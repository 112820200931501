import React from 'react';
import classes from './Skills.module.css';
import Solarsystem from './Solarsystem.jsx';
import Escalera from './Escalera.jsx';
import Preescalera from './Preescalera.jsx';
import Presolarsystem from './Presolarsystem.jsx';

import SeparatorescaleraSup from './../../media/separatorescalerasup.svg';

const Skills = () => {
  return (

    <div className={classes.Container}>
      <Presolarsystem />
      <Solarsystem />
      <img src={SeparatorescaleraSup} alt='/' className={classes.SeparatorEscImg}></img>
      <Preescalera />
      <Escalera />

    </div>
  )
}

export default Skills
