import {useState, useEffect, useRef} from 'react';
import classes from './Navbar.module.css';
import NavLinks from './NavLinks.jsx';
import Hamburger from '../../media/hamburger.svg';
import Closehamburger from '../../media/close.svg';



const MobileNavigation = () => {

  const [open, setOpen] = useState(false);
  const navRef = useRef(null);

  const hamburgerIcon = <img src={Hamburger} className={classes.Hamburger} alt='Abrir Menu' size='40px'
  onClick={() => setOpen(!open)}></img>

  const closeIcon = <img src={Closehamburger} className={classes.Hamburger} alt='Abrir Menu' size='40px'
  onClick={() => setOpen(!open)}></img>

  const closeMobileMenu = () => setOpen(false);
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeMobileMenu();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <nav className={classes.MobileNavigation} ref={navRef}>
      {open ? closeIcon : hamburgerIcon}
      {open && <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu} />}
  
    </nav>
  );
}

export default MobileNavigation;