import React from 'react'
import classes from './Escalera.module.css'
import Escalones2 from './Escalones2.jsx'
import { useTranslation } from "react-i18next";
import Separatorescalerainf from './../../media/separatorescalerainf.svg';

const Escalera = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className={classes.Container}>

      <div className={classes.EscaleraContainer}>
      <Escalones2 H2='NPM' H3={t("tools.npm")} />
      <Escalones2 H2='MySQL' H3={t("tools.mysql")} />
      <Escalones2 H2='SQL Server' H3={t("tools.sqlserver")} />
      <Escalones2 H2='Git' H3={t("tools.git")} />
      <Escalones2 H2='GitHub' H3={t("tools.github")} />
      <Escalones2 H2='Next.js' H3={t("tools.next")} />
      <Escalones2 H2='Vite' H3={t("tools.vite")} />
      <Escalones2 H2='Docker' H3={t("tools.docker")} />
      <Escalones2 H2='Postman' H3={t("tools.postman")} />
      <Escalones2 H2='Maven' H3={t("tools.maven")} />
      <Escalones2 H2='Visual Studio Code' H3={t("tools.vscode")} />
      <Escalones2 H2='Visual Studio Community' H3={t("tools.vscomu")} />
      <Escalones2 H2='Eclipse' H3={t("tools.eclipse")} />
      <Escalones2 H2='Bootstrap' H3={t("tools.bootstrap")} />
      <Escalones2 H2='TailwindCSS' H3={t("tools.tailwind")} />
      <Escalones2 H2='SASS' H3={t("tools.sass")} />
      <Escalones2 H2='Adobe Photoshop' H3={t("tools.photoshop")} />
      <Escalones2 H2='Adobe Illustrator' H3={t("tools.illustrator")} />
      <Escalones2 H2='Figma' H3={t("tools.figma")} />
      <Escalones2 H2='Sony Vegas' H3={t("tools.sonyvegas")} />
      <Escalones2 H2='Slack' H3={t("tools.slack")} />
      <Escalones2 H2='Trello' H3={t("tools.trello")} />
      <Escalones2 H2='Expo' H3={t("tools.expo")} />

    </div>

    <img src={Separatorescalerainf} alt='/' className={classes.SeparatorEscImg}></img>    
    </div>
  )
}

export default Escalera
