import React from 'react'
import classes from './Home.module.css'
import Parallax from './Parallax.jsx'

const Home = () => {
  return (
    <div className={classes.Container} id='Home'>
      <Parallax />
    </div>
  )
}

export default Home
