import React from 'react';
import { useScroll, useTransform, motion } from 'framer-motion';
import classes from './About2.module.css'
import { useTranslation } from "react-i18next";

const About2 = () => {

    // eslint-disable-next-line no-unused-vars
    const [t, i18n] = useTranslation("global");

  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress,[0, 0.5, 1],['80vw', '-110vw', '100vw']);
  const scale = useTransform(scrollYProgress, [0, 1], [0.5, 1]);
  const opacity = useTransform(scrollYProgress, [0, 0.1], [0, 1]);


  return (
    <div className={classes.Container}>
      <div className={classes.Presentation}>
        <h3 className={classes.Titulo}>{t("about.firstphrase")}</h3>
        <h3 className={classes.Titulo}>{t("about.secondphrase")}</h3>
        <h3 className={classes.Titulo}>{t("about.thirdphrase")}</h3>
      </div>

      <div className={classes.seaContainer}>
  <motion.div className={classes.submarine__container}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '400px',
        height: '200px',
        opacity,
        scale,
        x,
      }}
    

  >
    <div className={classes.light}></div>
    <div className={classes.submarine__periscope}></div>
    <div className={classes.submarine__periscopeglass}></div>
    <div className={classes.submarine__sail}>
      <div className={`${classes.submarine__sailshadow} ${classes.dark1}`}>
      </div>
      <div className={`${classes.submarine__sailshadow} ${classes.light1}`}></div>
      <div className={`${classes.submarine__sailshadow} ${classes.dark2}`}></div>
    </div>
    <div className={classes.submarine__body}>
      <div className={`${classes.submarine__window} ${classes.one}`}>

      </div>
      <div className={`${classes.submarine__window} ${classes.two}`}>

      </div>
      <div className={classes.submarine__shadowdark}></div>
      <div className={classes.submarine__shadowlight}></div>
      <div className={classes.submarine__shadowarcLight}></div>
    </div>
    <div className={classes.submarine__propeller}>
      <div className={classes.propeller__perspective}>
      <div className={`${classes.submarine__propellerparts} ${classes.darkOne}`}></div>
      <div className={`${classes.submarine__propellerparts} ${classes.lightOne}`}></div>
      </div>        
    </div>
  </motion.div>
  <motion.div className={classes.bubbles__container}
        style={{
          position: 'absolute',
          top: 0,
          left: 220,
          width: '400px',
          height: '200px',
          opacity,
          scale,
          x,
        }}
  
  >
    <span className={`${classes.bubbles} ${classes.bubble1}`}></span>
    <span className={`${classes.bubbles} ${classes.bubble2}`}></span>
    <span className={`${classes.bubbles} ${classes.bubble3}`}></span>
    <span className={`${classes.bubbles} ${classes.bubble4}`}></span>
  </motion.div>



</div>

    </div>
  )
}

export default About2
