import React from 'react';
import classes from './Loader.module.css';

const Loader = () => {

  const numOfPlanets = 50
  const colorArray = ['aliceblue', 'aqua', 'aquamarine', 'azure', 'blueviolet', 'blue', 'cadetblue', 'chartreuse', 'coral', 'cornflowerblue', 'cyan', 'crimson', 'darkblue', 'darkcyan', 'darkgoldenrod', 'darkmagenta', 'darkseagreen', 'darkorange', 'darkslateblue', 'darkorchid', 'darkslategray', 'darkturquoise', 'deeppink', 'darkviolet', 'dodgerblue', 'floralwhite', 'fuchsia', 'gold', 'greenyellow', 'honeydew', 'hotpink', 'indigo', 'lawngreen', 'lightblue', 'lightcyan', 'lightgreen', 'lightskyblue', 'lightsalmon', 'lightseagreen', 'mediumslateblue', 'mediumpurple', 'midnightblue', 'paleturquoise', 'palegreen'];

  const renderPlanets = () => {
    const planets = [];

    for (let i = 0; i < numOfPlanets; i++) {
      const randHeight = Math.random() * 600 + 0.4 * 600;
      const randWidth = Math.random() * 600 + 0.4 * 600;

      const spanStyle = {
        height: `${randHeight}px`,
        width: `${randWidth}px`,
        animationDuration: `${Math.floor(Math.random() * 10000 + 10000)}ms`,
        animationDelay: `-${Math.floor(Math.random() * 20000)}ms`,
      };

      const planetSize = Math.random() * 12 + 1;
      const divStyle = {
        height: `${planetSize}px`,
        width: `${planetSize}px`,
        background: getRandomColor(),
        boxShadow: `0 0 1px 1px ${getRandomColor()}`,
      };

      const planetElement = (
        <span className={classes.span} key={i} style={spanStyle}>
          <div style={divStyle} />
        </span>
      );

      planets.push(planetElement);
    }

    return planets;
  };

  const getRandomColor = () => {
    const randomColorIndex = Math.floor(Math.random() * colorArray.length);
    return colorArray[randomColorIndex];
  };

  return (
    <div className={classes.Loader}>
      <div className={classes.Space}>
        <div className={`${classes.ExSpan} ${classes.Planet} ${classes.SunA}`} ></div>
        <div className={`${classes.ExSpan} ${classes.Planet} ${classes.SunB}`}> </div>
        {renderPlanets()}
      </div>
    </div>
  );
};

export default Loader;
