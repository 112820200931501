import React, { useState, useEffect } from 'react';
import classes from './Parallax.module.css';
// import Stars from './Stars.jsx'

const Parallax = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={classes.parallaxContainer}>
      <div
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.10}px, 0)`,
        }}>

      {/* <Stars /> */}
      </div>
      <div
        className={`${classes.layer} ${classes.layerSol}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.9}px, 0)`,
        }}>
        <div className={classes.stars}>
        <div className={`${classes.star} ${classes.a}`}></div>
        <div className={`${classes.star} ${classes.b}`}></div>
      </div>
      </div>
      <div
        className={`${classes.layer} ${classes.layer0}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.9}px, 0)`,
        }}
      />
      <div
        className={`${classes.layer} ${classes.layer1}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.8}px, 0)`,
        }}
      />
      <div
        className={`${classes.layer} ${classes.layer2}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.7}px, 0)`,
        }}
      />
      <div
        className={`${classes.layer} ${classes.layer3}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.6}px, 0)`,
        }}
      />
      <div
        className={`${classes.layer} ${classes.layer4}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.5}px, 0)`,
        }}
      />
      <div
        className={`${classes.layer} ${classes.layer5}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.4}px, 0)`,
        }}
      />
      <div
        className={`${classes.layer} ${classes.layer6}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.3}px, 0)`,
        }}
      />
      <div
        className={`${classes.layer} ${classes.layer7}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.2}px, 0)`,
        }}
      />
      <div
        className={`${classes.layer} ${classes.layer8}`}
        style={{
          transform: `translate3d(0, ${scrollPosition * 0.1}px, 0)`,
        }}
      />
    </div>
  );
};

export default Parallax;
