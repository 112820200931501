import React, {useState} from 'react';
import classes from './ProjectsCards.module.css';
// import {AnimatePresence, motion} from "framer-motion";

const ProjectsCards = (props) => {

  const [flip, setFlip] = useState(false);

  return (

      <div 
      className={`${classes.MarcoCard} ${flip ? classes.flip : ''}`}

      onClick={() => setFlip(!flip)}
      >
        <div className={classes.FrontCard}>

        <div className={classes.MarcoTextFront}>
          <h3 layout className={classes.TitleProject}>{props.titleproject}</h3>
          <img className={classes.IconProject} src={props.iconproject} alt={props.altproject} />
        </div>
        <div className={classes.MarcoImage}>
          <img 
            className={classes.ImageProject} 
            src={props.imageproject} 
            alt={props.altproject} 
          />
        </div>
        </div>

        <div className={classes.BackCard}>

              <div className={classes.MarcoText}>
                <h3>{props.titleproject2}</h3>
                <p className={classes.TextProject}>{props.textproject}</p>
                <p className={classes.InfoProject}>{props.infoproject}</p>
              </div>
              <div className={classes.MarcoButton}>
                <a href={props.siteproject} target='S_BLANK'><button 
                className={classes.Button}
                >{props.buttonproject}</button></a>
                <a href={props.videosite} target='S_BLANK'><button 
                className={classes.Button}
                >{props.buttonvideo}</button></a>
              </div>
        </div>



      </div>

  );
};

export default ProjectsCards;