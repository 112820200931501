import React from 'react'
import classes from './Preabout.module.css';

const Preabout = () => {
  return (
    <div className={classes.PreaboutContainer}>
      
    </div>
  )
}

export default Preabout
