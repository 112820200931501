import React from 'react';
import classes from './Preprojects.module.css';
import PageHeader from './../PageHeader/PageHeader.jsx';
import { useTranslation } from "react-i18next";



const Preprojects = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className={classes.ContainerPreprojects}>

     <PageHeader title={t("projects.titulo")}/>
     
    </div>
  )
}

export default Preprojects
