import React, { useState } from 'react';
import classes from './About.module.css';
import { motion } from 'framer-motion';
import profile1 from './../../media/about/linkedin4.jpg';
import profile2 from './../../media/about/linkedin5.jpg';
import profile3 from './../../media/about/linkedin6.jpg';
import { useTranslation } from "react-i18next";

const About = () => {

  // eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation("global");

  const [hover, setHover] = useState(false);

  const [isUnfolded, setIsUnfolded] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const profileImages = [profile1, profile2, profile3];

  const getNextImageIndex = () => {
    return (currentImageIndex + 1) % profileImages.length;
  };

  const getNextImage = () => {
    setCurrentImageIndex(getNextImageIndex());
  };

  const handleimghover = () => {
    setIsUnfolded(!isUnfolded);
    getNextImage();
  };

  return (
    <div className={classes.Container}>
      <div className={classes.Presentation}>
        <h1 className={classes.Titulo}>{t("about.hi_there")}{" "}
          <span className={hover ? classes.Hovered : ''} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>YamilH</span>
        </h1>

        {hover && (
        <motion.h2 className={classes.Titulo2}
        initial={{ opacity: 0, y: -5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -5 }}
        transition={{ duration: 0.4 }}
        >{t("about.whoami")}</motion.h2>
        )}   


        {hover && (
          <motion.div className={classes.HoverReveal}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1,  y: [0, 50, 0] }}
          exit={{ opacity: 0,  y: [0, 50, 0] }}
          transition={{ duration: 1.2 }}
          whileHover={handleimghover }
          >
            <img src={profileImages[currentImageIndex]} alt='profilepic' className={classes.HoverRevealImg}></img>
          </motion.div>

      )}

      </div>
    </div>
  );
};

export default About;