import React, {useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import "normalize.css/normalize.css"
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import global_en from "./translations/en/global.json";
import global_sp from "./translations/sp/global.json";
import global_po from "./translations/po/global.json";

import Loader from './components/Loader/Loader.jsx'

i18next.init({
  interpolation: {escapeValue: false},
  lng: "en",
  resources: {
    en: {
      global: global_en
    },
    sp: {
      global: global_sp
    },
    po: {
      global: global_po
    }

  }
});

const Root = () => {
  const [charging, setCharging] = useState(false);

  useEffect(() => {
		setCharging(true);
		setTimeout(() => {
			setCharging(false);
		}, 6000);
	}, []);

  return (
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    {charging ? <Loader /> : <App />}
    </I18nextProvider>
  </React.StrictMode>
);
}
ReactDOM.render(<Root />, document.getElementById('root'));
