import React, { useState } from "react";
import classes from './Solarsystem.module.css'

const Solarsystem = () => {

  const [isActiveA, setActiveA] = useState(false);
  const [isActiveB, setActiveB] = useState(false);
  const [isActive1, setActive1] = useState(false);
  const [isActive2, setActive2] = useState(false);
  const [isActive3, setActive3] = useState(false);
  const [isActive4, setActive4] = useState(false);
  const [isActive5, setActive5] = useState(false);
  const [isActive6, setActive6] = useState(false);
  const [isActive7, setActive7] = useState(false);
  const [isActive8, setActive8] = useState(false);
  const [isActive9, setActive9] = useState(false);
  const [isActive10, setActive10] = useState(false);
  // const [selected, setSelected] = useState(false);

  const handleClickA = () => {
    setActiveA(!isActiveA);
  };
  const handleClickB = () => {
    setActiveB(!isActiveB);
  };
  const handleClick1 = () => {
    setActive1(!isActive1);
  };
  const handleClick2 = () => {
    setActive2(!isActive2);
  };
  const handleClick3 = () => {
    setActive3(!isActive3);
  };
  const handleClick4 = () => {
    setActive4(!isActive4);
  };
  const handleClick5 = () => {
    setActive5(!isActive5);
  };
  const handleClick6 = () => {
    setActive6(!isActive6);
  };
  const handleClick7 = () => {
    setActive7(!isActive7);
  };
  const handleClick8 = () => {
    setActive8(!isActive8);
  };
  const handleClick9 = () => {
    setActive9(!isActive9);
  };
  const handleClick10 = () => {
    setActive10(!isActive10);
  };


  return (
    <div className={classes.ContainerSolar}>

      <div className={classes.Space}>
        <div className={`${classes.ExSpan} ${isActiveA ? classes.PlanetFast : classes.Planet} ${classes.SunA} `}></div>
        <div className={`${classes.ExSpan} ${isActiveB ? classes.PlanetFast : classes.Planet} ${classes.SunB} `}></div>
        <div className={`${classes.ExSpan} ${isActive1 ? classes.PlanetFast : classes.Planet} ${classes.Planet1}`} ></div>
        <div className={`${classes.ExSpan} ${isActive2 ? classes.PlanetFast : classes.Planet} ${classes.Planet2}`}></div>
        <div className={`${classes.ExSpan} ${isActive3 ? classes.PlanetFast : classes.Planet} ${classes.Planet3}`}></div>
        <div className={`${classes.ExSpan} ${isActive4 ? classes.PlanetFast : classes.Planet} ${classes.Planet4}`}></div>
        <div className={`${classes.ExSpan} ${isActive5 ? classes.PlanetFast : classes.Planet} ${classes.Planet5}`}></div>
        <div className={`${classes.ExSpan} ${isActive6 ? classes.PlanetFast : classes.Planet} ${classes.Planet6}`}></div>
        <div className={`${classes.ExSpan} ${isActive7 ? classes.PlanetFast : classes.Planet} ${classes.Planet7}`}></div>
        <div className={`${classes.ExSpan} ${isActive8 ? classes.PlanetFast : classes.Planet} ${classes.Planet8}`}></div>
        <div className={`${classes.ExSpan} ${isActive9 ? classes.PlanetFast : classes.Planet} ${classes.Planet9}`}></div>
        <div className={`${classes.ExSpan} ${isActive10 ? classes.PlanetFast : classes.Planet} ${classes.Planet10}`}></div>


      </div>

    <div className={classes.ListaDePlanetas}>
      <button className={`${classes.ButtonsPlanetas} ${isActiveA ? classes.SelectedA : ''}`} onClick={handleClickA}>HTML</button>
      <button className={`${classes.ButtonsPlanetas} ${isActiveB ? classes.SelectedB : ''}`} onClick={handleClickB}>CSS</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive1 ? classes.Selected1 : ''}`} onClick={handleClick1}>Javascript</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive2 ? classes.Selected2 : ''}`} onClick={handleClick2}>React</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive3 ? classes.Selected3 : ''}`} onClick={handleClick3}>Angular</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive4 ? classes.Selected4 : ''}`} onClick={handleClick4}>TypeScript</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive5 ? classes.Selected5 : ''}`} onClick={handleClick5}>React Native</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive6 ? classes.Selected6 : ''}`} onClick={handleClick6}>SQL</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive7 ? classes.Selected7 : ''}`} onClick={handleClick7}>Node</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive8 ? classes.Selected8 : ''}`} onClick={handleClick8}>C#</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive9 ? classes.Selected9 : ''}`} onClick={handleClick9}>.NET</button>
      <button className={`${classes.ButtonsPlanetas} ${isActive10 ? classes.Selected10 : ''}`} onClick={handleClick10}>Java</button>
    </div>
   </div>
  )
}

export default Solarsystem
