import React from 'react';
import Home from './components/Home/Home.jsx'
import Abouts from './components/About/Abouts.jsx'
import Skills from './components/Skills/Skills.jsx'
import Projects from './components/Projects/Projects.jsx'
import Contact from './components/Contact/Contact.jsx'
import Footer from './components/Footer/Footer.jsx'
import Navbar from './components/Navbar/Navbar.jsx'


function App() {


  return (
    <div className="App">

				<>
        <Home />
        <Navbar />
        <Abouts />
        <Skills />
        <Projects />
        <Contact />
        <Footer />

        </>
    </div>
  );
}

export default App;
