import React from 'react';
import classes from './Preescalera.module.css';
import PageHeader from '../PageHeader/PageHeader.jsx';
import { useTranslation } from "react-i18next";


const Preescalera = () => {

  // eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation("global");

  return (
    <div className={classes.ContainerPreescalera}>

      <PageHeader title={t("skills.titulo2")}/>

    </div>
  )
}

export default Preescalera
