import React from 'react'
import classes from './Grogu.module.css'
import ImgGrogu from './../../media/grogu/grogu.png';
import ImgRoca from './../../media/grogu/roca.png';

const Grogu = () => {
  return (
    <div className={classes.ContainerGrogu}>
      
      <a href="https://github.com/YamilH" rel="noreferrer" target="_blank">
      <div className={classes.Burbuja3}></div></a> 
      <div className={classes.SpacerTop}></div>
      <div className={classes.GroguBox}>
      <a href="mailto:yamilhamui@gmail.com">
      <div className={classes.Burbuja}></div></a>

        <img className={classes.Imagengrogu} src={ImgGrogu} alt='/'></img>
        <a href="https://ar.linkedin.com/in/yamilhamui" rel="noreferrer" target="_blank">
          <div className={classes.Burbuja2}></div></a>
          <div className={classes.spacer}></div>
          <img className={classes.Imagengrogu2} src={ImgRoca} alt='/'></img>


        </div>

    </div>
  )
}

export default Grogu
