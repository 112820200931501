import React from 'react'
import Formcontact from './Formcontact.jsx'
import Grogu from './Grogu.jsx'
import classes from './Contact.module.css'

const Contact = () => {
  return (
    <div className={classes.ContainerContact}>
      <Formcontact />
      <Grogu />

    </div>
  )
}

export default Contact
