import React from 'react'
import About from './About.jsx'
import About2 from './About2.jsx'
import Preabout from './Preabout.jsx'


const Abouts = () => {
  return (
    <div id='About'>
      <Preabout />
      <About />
      <About2 />
    </div>
  )
}

export default Abouts
