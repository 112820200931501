import React, { useRef, useState } from 'react';
import classes from './Godzilla.module.css';

import { useInView, motion } from 'framer-motion';

import Cloud from './../../media/godzi/clouds.png';
import Mountain from './../../media/godzi/mountain.png';
import TreeA from './../../media/godzi/trees1.png';
import TreeB from './../../media/godzi/trees2.png';
import Gojira from './../../media/godzi/gojira.png';
import olahulk from './../../media/olahulk7.svg';

const Godzilla = () => {

  const ref = useRef(null);
  const isInView = useInView(ref);

  const [treeAVisible, setTreeAVisible] = useState(false);
  const treeARef = useRef(null);
  const treeAIsInView = useInView(treeARef);
  if (treeAIsInView && !treeAVisible) {
    setTreeAVisible(true);
  }

  const [treeBVisible, setTreeBVisible] = useState(false);
  const treeBRef = useRef(null);
  const treeBIsInView = useInView(treeBRef);
  if (treeBIsInView && !treeBVisible) {
    setTreeBVisible(true);
  }

  const [godziVisible, setGodziVisible] = useState(false);
  const godziRef = useRef(null);
  const godziIsInView = useInView(godziRef);
  if (godziIsInView && !godziVisible) {
    setGodziVisible(true);
  }

  const [godziImgVisible, setGodziImgVisible] = useState(false);
  const godziImgRef = useRef(null);
  const godziImgIsInView = useInView(godziImgRef);
  if (godziImgIsInView && !godziImgVisible) {
    setGodziImgVisible(true);
  }
  


  return (
    <div className={classes.ContainerGodzilla} ref={ref}>
      <div className={classes.CloudGodzilla}>
        <img src={Cloud} alt='cloud' className={classes.ImgCloud} />
      </div>
    <div className={classes.MountainGodzilla}>
       <img src={Mountain} alt='cloud' className={classes.ImgMountain} />
    </div>
    <div className={classes.TreesGodzilla}>
      <div 
      className={`${classes.TreeA} ${treeAVisible ? classes.TreeAAnimated : '' }`}
      ref={treeARef}>
      
        <img src={TreeA} alt='cloud' className={classes.ImgTreeA} />
      </div>
      <div 
      className={`${classes.TreeB} ${treeBVisible ? classes.TreeBAnimated : '' }`}
      ref={treeBRef}>
        <img src={TreeB} alt='cloud' className={classes.ImgTreeB} />
      </div>
    </div>
      <div
        className={`${classes.Gojira} ${godziVisible ? classes.GojiraAnimated : '' }`}
        ref={godziRef}
        style={{
          transform: isInView ? "none" : "translateX(-600px)",
          opacity: isInView ? 1 : 1,
          transition: "all 15s linear 0.5s"
        }}
      >
        <img src={Gojira} alt='cloud'
        className={`${classes.ImgGojira} ${godziImgVisible ? classes.ImgGojiraAnimated : '' }`}
        ref={godziImgRef}
        />
      </div>
      <div className={classes.OlahulkContainer}>
        <img src={olahulk} className={classes.Olahulk} alt='/' id='Contact'/>
      </div>
    </div>
  )
}

export default Godzilla
