import React from 'react'
import ProjectsCards from './ProjectsCards.jsx'
import Godzilla from './Godzilla.jsx'
import Preprojects from './Preprojects.jsx'
import classes from './Projects.module.css'
import imgProject1 from './../../media/proje/twitterbot.jpg';
import imgProject2 from './../../media/proje/escaramujo.jpg';
import imgProject3 from './../../media/proje/leogimenezart.jpg';
import imgProject4 from './../../media/proje/fluir.jpg';
import imgProject5 from './../../media/proje/clim.jpg';
import imgProject6 from './../../media/proje/respira.jpg';
import imgProject7 from './../../media/proje/nadia.jpg';
import imgProject9 from './../../media/proje/coinviewpng.png';
// import imgProject10 from './../../media/proje/tradetracker.jpg';
// import imgProject11 from './../../media/proje/pymepro.jpg';
import imgProject12 from './../../media/proje/yanina.jpg';
import imgProject13 from './../../media/proje/piercing.jpg';
import imgProject14 from './../../media/proje/victoria.jpg';
import imgProject15 from './../../media/proje/speaker.jpg';
import imgProject16 from './../../media/proje/warehouse.jpg';

import iconHtmlcssjs from './../../media/proje/icons/htmlcssjs.png';
import iconReact from './../../media/proje/icons/react.svg';
import iconAngular from './../../media/proje/icons/angular.svg';
import iconReactnative from './../../media/proje/icons/react-native.png';
import iconJava from './../../media/proje/icons/java.svg';
import iconCSharpDotNet from './../../media/proje/icons/csharp-dotnet.png';


import { useTranslation } from "react-i18next";

const Projects = () => {
  // eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation("global");
  return (
    <div className={classes.Container} id='Projects'>
  
     <Preprojects />

      <div className={classes.ContainerProjects}>
      <ProjectsCards 
      titleproject='Twitter Bot'
      textproject={t("projects.twitterbot")}
      infoproject='Node.js. NPM. API'
      imageproject={imgProject1}
      altoproject=''
      siteproject='https://twitter.com/CodesColor'
      videosite='https://www.youtube.com/channel/UCPbY1d58dRIZuWmPsjViLuA'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Escaramujo'
      textproject={t("projects.escaramujo")}
      infoproject='Vanilla HTML, CSS, JavaScript. Mobile First. SEO. Custom Domain. SSL'
      imageproject={imgProject2}
      altoproject=''
      iconproject={iconHtmlcssjs}
      siteproject='https://escaramujo.netlify.app/'
      videosite='https://www.youtube.com/watch?v=88KIRDHW2Rs'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='LeoGimenezArt'
      textproject={t("projects.leogimenezart")}
      infoproject='Vanilla HTML, CSS, JavaScript. Mobile First. Horizontal. SEO. Custom Domain. SSL'
      imageproject={imgProject3}
      altoproject=''
      iconproject={iconHtmlcssjs}
      siteproject='https://leogimenezart.netlify.app'
      videosite='https://www.youtube.com/watch?v=XaApAVXf1Vw'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Fluir'
      textproject={t("projects.fluir")}
      infoproject='React.js. Mobile First. SEO'
      imageproject={imgProject4}
      altoproject=''
      iconproject={iconReact}
      siteproject='https://fluir-website.netlify.app/'
      videosite='https://www.youtube.com/watch?v=dbvuf-NgnZY'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Clim'
      textproject={t("projects.clim")}
      infoproject='React.js. Mobile First. Tailwind.CSS. API'
      imageproject={imgProject5}
      altoproject=''
      iconproject={iconReact}
      siteproject='https://clim.yamilh.vercel.app/'
      videosite='https://www.youtube.com/watch?v=_GXX21T_TQY'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Respira'
      textproject={t("projects.respira")}
      infoproject='React.js. Mobile First. Github Collab with Juligon. Custom Domain. API. SSL'
      imageproject={imgProject6}
      altoproject=''
      iconproject={iconReact}
      siteproject='https://respiratuciudad.com.ar/'
      videosite='https://www.youtube.com/watch?v=Dcm0rTctGqI'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Nadia Bobillo PH'
      textproject={t("projects.nadia")}
      infoproject='React.js Next.js. Tailwind.css'
      imageproject={imgProject7}
      altoproject=''
      iconproject={iconReact}
      siteproject='https://nadiabobillo.vercel.app/'
      videosite='https://www.youtube.com/watch?v=zWnTHm7A7JM'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='CoinView'
      textproject={t("projects.reactnativeproject")}
      infoproject='React Native. TypeScript. API. Expo'
      imageproject={imgProject9}
      altoproject=''
      iconproject={iconReactnative}
      siteproject='https://expo.dev/@yamilh/coinview?serviceType=classic&distribution=expo-go'
      videosite='https://www.youtube.com/watch?v=3npXEEWtLKs'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      {/* <ProjectsCards 
      titleproject='Trade Tracker'
      textproject={t("projects.tradetracker")}
      infoproject='React.js. MERN. Backend. MongoDB'
      imageproject={imgProject10}
      altoproject=''
      siteproject='https://admin-frontend-21hu.onrender.com/'
      videosite='https://www.youtube.com/channel/UCPbY1d58dRIZuWmPsjViLuA'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      /> */}

      {/* <ProjectsCards 
      titleproject='PymePro'
      textproject={t("projects.pymepro")}
      infoproject='React.js Vite.js. TypeScript. MERN. Backend. MongoDB. Machine Learning'
      imageproject={imgProject11}
      altoproject=''
      siteproject='https://pymepro-client.onrender.com/'
      videosite='https://www.youtube.com/channel/UCPbY1d58dRIZuWmPsjViLuA'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      /> */}
      
      <ProjectsCards 
      titleproject='Yanina Romani'
      textproject={t("projects.yanina")}
      infoproject='React.js Vite.js. TypeScript. Tailwind.CSS. Mobile First'
      imageproject={imgProject12}
      altoproject=''
      iconproject={iconReact}
      siteproject='https://yaninaromani.com.ar/'
      videosite='https://www.youtube.com/watch?v=GS81znePsAg'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Piercing'
      textproject={t("projects.piercing")}
      infoproject='Angular.js TypeScript. SASS. Mobile First.'
      imageproject={imgProject13}
      altoproject=''
      iconproject={iconAngular}
      siteproject='https://rose-angular.vercel.app/'
      videosite='https://www.youtube.com/watch?v=c3sfDt6wPcA'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Victoria'
      textproject={t("projects.victoria")}
      infoproject='React.js Vite.js. TypeScript. Tailwind.CSS. Mobile First'
      imageproject={imgProject14}
      altoproject=''
      iconproject={iconReact}
      siteproject='https://victoriatd.netlify.app/'
      videosite='https://www.youtube.com/watch?v=4p5ow4sb33g'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Speaker'
      textproject={t("projects.speaker")}
      infoproject='Java CRUD MySQL Docker Maven Tomcat Javascript DOM'
      imageproject={imgProject15}
      altoproject=''
      iconproject={iconJava}
      siteproject='https://github.com/YamilH/speaker2-back'
      videosite='https://www.youtube.com/watch?v=zEAkhg9HncY'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='Warehouse'
      textproject={t("projects.warehouse")}
      infoproject='Java CRUD MySQL Docker Maven Tomcat Javascript DOM'
      imageproject={imgProject16}
      altoproject=''
      iconproject={iconJava}
      siteproject='https://github.com/YamilH/warehouse-back'
      videosite='https://www.youtube.com/watch?v=QXY8Fr8teIA'
      buttonproject={t("projects.button")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='.NET Project in construction'
      textproject={t("projects.buttondev")}
      infoproject='need to complete'
      imageproject=''
      altoproject=''
      iconproject={iconCSharpDotNet}
      videosite='https://www.youtube.com/channel/UCPbY1d58dRIZuWmPsjViLuA'
      buttonproject={t("projects.buttondev")}
      buttonvideo={t("projects.video")}
      />

      <ProjectsCards 
      titleproject='.NET Project in construction'
      textproject={t("projects.buttondev")}
      infoproject='need to complete'
      imageproject=''
      altoproject=''
      iconproject={iconCSharpDotNet}
      videosite='https://www.youtube.com/channel/UCPbY1d58dRIZuWmPsjViLuA'
      buttonproject={t("projects.buttondev")}
      buttonvideo={t("projects.video")}
      />


      </div>

    <Godzilla />

    </div>

    
  )
}

export default Projects
