import React from 'react';

import {motion} from 'framer-motion';

import { useTranslation } from "react-i18next";


const NavLinks = (props) => {

  const [t, i18n] = useTranslation("global");

  const animateFrom = {opacity: 0, y: -40}
  const animateTo = {opacity: 1, y: 0}

  return (
    <ul>
    <motion.li
      initial={animateFrom}
      animate={animateTo}
      transition={{delay: 0.05}}
      onClick={() => props.isMobile && props.closeMobileMenu()}><a href="/#Home">{t("navbar.home")}</a></motion.li>
    <motion.li 
      initial={animateFrom}
      animate={animateTo}
      transition={{delay: 0.10}}
      onClick={() => props.isMobile && props.closeMobileMenu()}><a href="/#About">{t("navbar.about")}</a></motion.li>
    <motion.li 
      initial={animateFrom}
      animate={animateTo}
      transition={{delay: 0.15}}
      onClick={() => props.isMobile && props.closeMobileMenu()}><a href="/#Skills">{t("navbar.skills")}</a></motion.li>
    <motion.li 
      initial={animateFrom}
      animate={animateTo}
      transition={{delay: 0.20}}
      onClick={() => props.isMobile && props.closeMobileMenu()}><a href="/#Projects">{t("navbar.projects")}</a></motion.li>
    <motion.li 
      initial={animateFrom}
      animate={animateTo}
      transition={{delay: 0.25}}
      onClick={() => props.isMobile && props.closeMobileMenu()}><a href="/#Contact">{t("navbar.contact")}</a></motion.li>
  </ul>
  );
}

export default NavLinks;