import React, {useState, useEffect} from 'react'
import classes from './Escalones2.module.css'

import { motion } from "framer-motion";

const Escalones2 = (props) => {

  const [palette, setPalette] = useState(Math.floor(Math.random() * 210));

  useEffect(() => {
    document.querySelectorAll('section h2').forEach((heading, i) => {
      const hue = palette + i * 15;
      const css = `background:hsl(${hue},85%,70%); color:hsl(${hue},45%,55%);`;
      heading.setAttribute('style', css);
      console.log(setPalette)
    });
  }, [palette]);

  const [isUnfolded, setIsUnfolded] = useState(false);
  const handleH2Click = () => {
    setIsUnfolded(!isUnfolded);
  };


  return (
      <section className={classes.Section}>
        <div className={classes.Article}>
          <motion.h2 
          className={classes.ArticleH2}
          onClick={handleH2Click}
          animate={{ marginRight: isUnfolded ? 30 : 0 }}
          >{props.H2}</motion.h2>
          <motion.h3 
          className={classes.ArticleH3}
          animate={{ opacity: isUnfolded ? 1 : 0 }}
          >{props.H3}</motion.h3>

        </div>
      </section>
      
  )
}

export default Escalones2
