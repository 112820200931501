import React, {useState} from 'react'
import classes from './Formcontact.module.css'
import { useTranslation } from "react-i18next";


const Formcontact = () => {

  // eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation("global");

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [messageSubmit, setMessageSubmit] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://eormfnoz04qrsf6.m.pipedream.net', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, email, subject, message }),
  })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      // handle success response
    })
    .then(setMessageSubmit(`${t("contact.messagesubmit1")} ${name}. ${t("contact.messagesubmit2")}`))
    .catch(error => {
      console.error(error);
      // handle error response
    });
  }



  return (
    <div className={classes.ContainerFormContact}>
       <h1 className={classes.TituloFormContact}>{t("contact.phrase")}</h1>
       <form className={classes.FormFormContact} onSubmit={handleSubmit}>
        <div className={classes.ColumnaFormContact}>
          <div>
            <label htmlFor='name' className={classes.LabelContact}>Name</label>
            <input id='name' className={classes.InputContact} type='text' placeholder={t("contact.name")} value={name} onChange={(e) => setName(e.target.value)} required />
          </div>      
          <div>
            <label htmlFor='email' className={classes.LabelContact}>Email</label>
            <input id='email' className={classes.InputContact} type='email' placeholder={t("contact.email")} value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
        </div>
        <div className={classes.MarginTopContact}>
          <label htmlFor='subject' className={classes.LabelContact}>Subject</label>
          <input id='subject' className={classes.InputContact} type='text' placeholder={t("contact.subject")} value={subject} onChange={(e) => setSubject(e.target.value)} required />
        </div>
        <div className={classes.MarginTopContact}>
          <label htmlFor='message' className={classes.LabelContact}>Message</label>
          <textarea id='message' className={classes.InputContact} cols='30' rows='6' placeholder={t("contact.message")} value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
        </div>
        <button type='submit' className={classes.ButtonContact}>{t("contact.submit")}</button><h3 className={classes.MessageSubmit}>{messageSubmit}</h3>
      </form>

    </div>
  )
}

export default Formcontact