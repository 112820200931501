import React from 'react'
import classes from './Presolarsystem.module.css';
import PageHeader from '../PageHeader/PageHeader.jsx';
import { useTranslation } from "react-i18next";

import Separator4 from './../../media/separadorazul2.svg';

const Presolarsystem = () => {

  const [t, i18n] = useTranslation("global");

  return (
    <div className={classes.ContainerPreSolarsystem}>

      <div id='Skills'></div>
      <img src={Separator4} alt='/' className={classes.Separator4}></img>

      <PageHeader title={t("skills.titulo")}/>

    </div>
  )
}

export default Presolarsystem
