import React, { useEffect } from 'react';
import classes from './Footer.module.css'
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    for (let i = 0; i < 10; i++) {
      let bubble = document.createElement('div');
      bubble.className = classes.bubble;

      bubble.style.cssText = `
        --blob-size: ${2 + Math.random() * 4}rem;
        --blob-distance: ${5 + Math.random() * 4}rem;
        --blob-position: ${-5 + Math.random() * 89}%;
        --blob-time: ${2 + Math.random() * 2}s;
        --blob-delay: ${-1 * (2 + Math.random() * 2)}s;
      `;

      document.querySelector(`.${classes.bubbleContainer}`).append(bubble);
    }
  }, []);

  return (
    <footer className={classes.footer}>
      <div className={classes.bubbleContainer}></div>
      <div className={classes.content}>
        <div className={classes.footertexts}>
          <p className={classes.FooterPp}>{t("footer.thanks")}</p>
          <div className={classes.footersubtexts}>
          <p className={classes.FooterP}>{t("footer.allrightsreserved")}</p>
          {/* <p></p> */}
          <p className={classes.FooterP }>{t("footer.poweredbyme")}</p>
          </div>
          </div>
        <div>{/* content */}</div>
      </div>
    </footer>
  );
};

export default Footer;
