import React, { useState, useEffect, useRef } from 'react';
import classes from './Idiomas.module.css';
import { useTranslation } from "react-i18next";

const Idiomas = () => {
  // eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation("global");

  const [openlanguage, setOpenlanguage] = useState (false);
  const menuRef = useRef(null);

  const toggleLanguageMenu = () => {
    setOpenlanguage(!openlanguage);
  };

  const handleClickOutsideMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenlanguage(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, []);

  return (
    <div className={classes.ContainerIdiomas} ref={menuRef}>
      <div className={classes.SelectedLanguage} onClick={toggleLanguageMenu}>
       ▼
      </div>
      {openlanguage && (
      <ul className={classes.UlLanguage}>
        <li className={classes.LiLanguage}>
          <a onClick={() => { i18n.changeLanguage('sp'); toggleLanguageMenu(); }} className={`${classes.ALanguage} ${classes.SpLanguage}`} href='/#'>Español</a>
        </li>
        <li>
          <a onClick={() => { i18n.changeLanguage('po'); toggleLanguageMenu(); }} className={`${classes.ALanguage} ${classes.PoLanguage}`} href='/#'>Português</a>
        </li>
        <li>
          <a onClick={() => { i18n.changeLanguage('en'); toggleLanguageMenu(); }} className={`${classes.ALanguage} ${classes.EnLanguage}`} href='/#'>English</a>
        </li>
      </ul>
       )}
    </div>
  )
}

export default Idiomas
