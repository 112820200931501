import React, {useState, useEffect} from 'react';
import classes from './Navbar.module.css'
import Navigation from './Navigation.jsx'
import MobileNavigation from './MobileNavigation.jsx';
import { motion } from "framer-motion";

import Idiomas from './Idiomas.jsx'
import Rojosun from './../../media/sunrojo.png';
import Blancosun from './../../media/sunblanco.png';

const Navbar = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.pageYOffset >= window.innerHeight - 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navbarStyles = {
    position: 'fixed',
    top: isFixed ? 0 : '-100px',
    width: "100%",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffaf00",
    // boxShadow: "0 2px 5px #ffaf00",
    // borderBottom: "3px solid #f29544",
    color: "#fff",
    zIndex: 100,
    transition: "all 0.3s ease-in-out"
  };


  const [clicked, setClicked] = useState(false);
  function handleClick() {
    setClicked((prevClicked) => !prevClicked);
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 6000);
  }


  return (
    <motion.div 
    className={isFixed ? classes.fixedNavbar : classes.Navbar}
    style={navbarStyles}
    initial={{ y: 80 }}
    animate={isFixed ? { y: 0 } : { y: 0 }}
    transition={{ duration: 0.0, ease: "easeInOut" }}
    >

      <Idiomas />

      <div className={classes.StarsNavLogo}>
          {/* <img src={LogoSuns} alt='/' className={classes.StarLogoImg} href="/#Home" /> */}
          <img src={Rojosun} alt='/' 
          className={`${classes.StarLogoImg} ${clicked && classes.StarClickLogoImg}`}
          onClick={handleClick} />
          <img src={Blancosun} alt='/' 
          className={`${classes.StarLogoImg2} ${clicked && classes.StarClickLogoImg2}`}
          onClick={handleClick} />
      </div>
      <Navigation />
      <MobileNavigation />
    </motion.div>
  );
};
export default Navbar;